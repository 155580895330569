import React, { cloneElement, ReactElement } from "react";
import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import { ProgramParticipantScoringStatus } from "../../../../../../services/types/scoringStatusTypes";
import { eGrayCellColors } from "./assessmentStatusColors";

export interface RaterStatusCellProps {
    participant: ProgramParticipantScoringStatus;
    instrumentId: number;
    raterId: number;
}

const RaterStatusCell: React.FC<RaterStatusCellProps> = (props) => {
    let invited: number = 0;
    let completed: number = 0;
    let minRequired: number = 0;
    let recievedLate: number = 0;
    let icon: ReactElement = <CheckCircleIcon />;
    let color: eGrayCellColors = eGrayCellColors.Green;

    const paxInstrument = props.participant.instruments.find(
        (i) => i.instrumentId === props.instrumentId
    );

    if (paxInstrument != null) {
        const raterDetails = paxInstrument.forms.find((f) => f.raterTypeId === props.raterId);
        // make sure it's not cancelled, if it is we leave all the counts at 0 so use the do not disturb icon/gray color
        if (paxInstrument.selfAssessmentStatusTypeId !== 5 && raterDetails != null) {
            minRequired = raterDetails.minimumRequired;
            invited = raterDetails.surveysSent;
            completed = raterDetails.receivedCount;
            recievedLate = raterDetails.receivedLateCount;
        }
    }

    let highlightCompleted: boolean = completed < minRequired;
    let highlightInvited: boolean = invited < minRequired;

    if (completed < minRequired) {
        icon = <WarningIcon />;
        color = eGrayCellColors.Orange;
    } else if (invited === 0) {
        icon = <DoNotDisturbIcon />;
        color = eGrayCellColors.Grey;
    } else if (completed === 0) {
        icon = <PanoramaFishEyeIcon />;
        color = eGrayCellColors.Blue;
    } else {
        icon = <CheckCircleIcon />;
        if (recievedLate > 0) {
            color = eGrayCellColors.Red;
        } else if (completed === invited) {
            color = eGrayCellColors.Green;
        } else {
            color = eGrayCellColors.Blue;
        }
    }

    icon = cloneElement(icon, { fontSize: "small", style: { color: color, marginLeft: 12 } });

    return (
        <>
            {icon}
            <Typography sx={{ pl: 2 }}>
                <span style={{ color: highlightCompleted ? eGrayCellColors.Orange : "inherit" }}>
                    {completed}
                </span>
                /
                <span style={{ color: highlightInvited ? eGrayCellColors.Orange : "inherit" }}>
                    {invited}
                </span>
            </Typography>
            {recievedLate > 0 ? (
                <Typography>
                    /<span style={{ color: eGrayCellColors.Red }}>{recievedLate}</span>
                </Typography>
            ) : null}
        </>
    );
};

export default RaterStatusCell;
