import * as React from "react";
import { useGetProgramScoringStatusQuery } from "../../../../../services/cclScoringStatusApi";
import { Alert, Box, Stack } from "@mui/material";
import AssessmentStatusDataGrid, { InstrumentInfo } from "./components/assessmentStatusDataGrid";
import { Participant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import ScoringRequestDrawer from "./components/scoringRequestDrawer/scoringRequestDrawer";
import { ProgramParticipantScoringStatus } from "../../../../../services/types/scoringStatusTypes";
import CclAlertDialog from "../../../../../components/common/cclAlertDialog";
import ComponentLoader from "../../../../../components/common/componentLoader";

export interface ScoringStatusPanelProps {
    disallowScoring?: boolean;
    sessionKey: number | undefined;
    sessionCode: string | undefined;
    handleJournalEntry?: (keys: string[]) => void;
    participant?: Participant;
    readOnly?: boolean;
}

const ScoringStatusPanel: React.FC<ScoringStatusPanelProps> = (props) => {
    const [showScoringRequestDrawer, setShowScoringRequestDrawer] = React.useState<boolean>(false);
    const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
    const [participantsToScore, setParticipantsToScore] = React.useState<
        ProgramParticipantScoringStatus[]
    >([]);

    const {
        data: programStatus,
        isLoading,
        isSuccess,
        isError,
    } = useGetProgramScoringStatusQuery(props.sessionKey as number, {
        skip: props.sessionKey === undefined,
    });

    const openScoringRequestDrawer = (selectedParticipantIds: number[]) => {
        if (selectedParticipantIds.length === 0) {
            setParticipantsToScore([]);
            return;
        }

        const selectedParticipants = selectedParticipantIds.reduce(function (
            acc: ProgramParticipantScoringStatus[],
            curr: number
        ) {
            const pax = programStatus?.participants.find((p) => p.participantId === curr);
            if (pax !== undefined) {
                acc.push(pax);
            }
            return acc;
        },
        []);

        setParticipantsToScore([...selectedParticipants]);
        setShowScoringRequestDrawer(true);
    };
    const handleScoreRequest = (scoreInstrumentIds: number[]) => {
        //TODO: implement actual scoring request later, but for now show a dialog
        setShowScoringRequestDrawer(false);
        setShowAlertDialog(true);
    };

    const handleEvent = (event: string, participantIds: number[]) => {
        switch (event) {
            case "sendraterreminders":
            case "sendraterinvitations":
            case "changeexpirationdate":
            case "viewparticipantcontactinfo":
            case "createjournalentry":
                if (!props.handleJournalEntry) return;
                props.handleJournalEntry(participantIds.map(String));
                break;
        }
    };

    if (isLoading) {
        return <ComponentLoader msg="Loading assessment status details..." />;
    }

    if (isSuccess) {
        let pax: ProgramParticipantScoringStatus;
        let instruments: InstrumentInfo[] = [];

        if (
            programStatus?.instruments == null ||
            programStatus?.instruments.length === 0 ||
            programStatus?.participants == null ||
            programStatus?.participants.length === 0
        ) {
            return (
                <Box>
                    <Alert severity="info">
                        No assessment status information found for this session.
                    </Alert>
                </Box>
            );
        }

        pax = programStatus.participants[0];
        instruments = programStatus.instruments.map((inst, index) => {
            let ii: InstrumentInfo = {
                instrumentId: +inst.id,
                hasRaters: inst.hasRaters,
                name: inst.name,
                shortName: inst.shortName,
                raters: [],
            };

            if (inst.hasRaters) {
                const paxInstrument = pax.instruments.find((i) => i.instrumentId === +inst.id);
                if (paxInstrument != null) {
                    ii.raters = paxInstrument.forms
                        .filter((f) => f.raterTypeName.toLowerCase() !== "self")
                        .map((frm) => {
                            return {
                                raterId: frm.raterTypeId,
                                raterName: frm.raterTypeName,
                                minRequired: frm.minimumRequired,
                                minRecommended: frm.minimumRecommended,
                                received: frm.receivedCount,
                                receivedLate: frm.receivedLateCount,
                            };
                        });
                }
            }
            return ii;
        });

        return (
            <Box width={1}>
                {isLoading ? null : (
                    <React.Fragment>
                        <CclAlertDialog
                            title="Scoring Function Not Yet Implemented"
                            msg="Scoring Function Not Yet Implemented"
                            open={showAlertDialog}
                            onOk={() => setShowAlertDialog(false)}
                            severity={"error"}
                        />
                        <ScoringRequestDrawer
                            open={showScoringRequestDrawer}
                            onClose={() => setShowScoringRequestDrawer(false)}
                            onScore={handleScoreRequest}
                            selectedParticipantCount={participantsToScore.length}
                            instruments={programStatus.instruments}
                        />
                    </React.Fragment>
                )}
                <AssessmentStatusDataGrid
                    hideScoreButton={props.disallowScoring}
                    instruments={instruments}
                    participants={
                        isLoading
                            ? []
                            : props.participant === undefined
                            ? programStatus.participants
                            : programStatus.participants.filter(
                                  (p) => p.participantId === props.participant?.esiKey
                              )
                    }
                    isRegistration={props.participant !== undefined}
                    isLoading={isLoading}
                    showAllRatersByDefault={true}
                    sessionKey={props.sessionKey}
                    sessionCode={props.sessionCode}
                    onScorePressed={openScoringRequestDrawer}
                    handleEvent={handleEvent}
                    readOnly={props.readOnly}
                />
            </Box>
        );
    }

    if (isError) {
        return (
            <Box>
                <Alert severity="error">
                    There was an error retrieving the assessment scoring status for this session.
                </Alert>
            </Box>
        );
    }

    // safety, should never get here
    return (
        <Stack width={1} spacing={2}>
            <Alert severity="error">
                Something went wrong retrieving assessment status information for this session.
            </Alert>
        </Stack>
    );
};

export default ScoringStatusPanel;
