import * as React from "react";
import { useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Alert, AlertTitle, Link, Stack, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../../layouts/standard";
import { useGetCclParticipantQuery } from "../../../services/cclTokenedEnterpriseParticipantApi";
import PageLoader from "../../../components/common/pageLoader";
import DetailPage from "../../../components/common/detailPage/detailPage";
import RegistrationGeneralPanel from "./general/registrationGeneralPanel";
import { Participant } from "../../../services/types/enterpriseParticipantApiTypes";
import { useGetCclEventByEventKeyQuery } from "../../../services/cclTokenedSessionApi";
import { getErrorMsg } from "../../../utilities/rtkQueryErrorHelpers";
import useCombinationKeyPress from "../../../hooks/useCombinationKeyPress";
import RegistrationJournalDrawer from "./registrationJournalDrawer";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../services/currentUserService/currentUserService";
import IndividualHistoryPanel from "../../../components/shared/individualHistoryPanel/individualHistoryPanel";
import { RouteAccess } from "../../../app/slices/allowedRoutesSlice";
import AssessmentsPanel from "../../sessions/sessionDetail/assessments/assessmentsPanel";

export type RegistrationDetailProps = {
    esiKey: string;
};

const RegistrationDetail: React.FC = (props) => {
    const [esKey, setEsKey] = useState<string>("");
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const [journalKey, setJournalKey] = React.useState<number>(Date.now());
    const params = useParams<RegistrationDetailProps>();
    const esikey = params.esiKey !== undefined ? params.esiKey : "";
    const {
        data: queryregistration,
        isLoading: regIsLoading,
        isSuccess: regIsSuccess,
        isError: regIsError,
        error: regError,
    } = useGetCclParticipantQuery(esikey, { skip: esikey === "" });
    const {
        data: session,
        isLoading: sessionIsLoading,
        isSuccess: sessionIsSuccess,
        isError: sessionIsError,
        error: sessionError,
    } = useGetCclEventByEventKeyQuery(esKey, { skip: esKey === "" });
    const detailEl = React.useRef<HTMLDivElement>(null);
    const claimsService = new UserClaimsService();
    const isUserAdmin = claimsService.IsUserCclAdminOrSuperAdmin();
    const allowJournalEntries =
        claimsService.IsUserCclAdminOrSuperAdmin() ||
        claimsService.IsUserNwaAdmin() ||
        claimsService.IsUserInGroups([eGroupIds.eClientAdmin]);

    const onKeyDown = (event: KeyboardEvent) => {
        setDrawerOpen(true);
        event.preventDefault();
    };
    useCombinationKeyPress(["j"], onKeyDown, detailEl.current);

    const emptyRegistration = {} as Participant;
    const registration =
        queryregistration == null
            ? emptyRegistration
            : JSON.parse(JSON.stringify(queryregistration));

    const isPartnerSession = session?.networkPartner !== "N/A";

    const [esiKeys, setEsiKeys] = useState<string[]>([]);

    React.useEffect(() => {
        if (!params.esiKey) return;
        let temp = esiKeys;
        temp.push(params.esiKey);
        setEsiKeys(temp);
        //eslint-disable-next-line
    }, [params]);

    const handleJournalEntry = (keys: string[]) => {
        let temp = esiKeys;
        temp.push(...keys);
        setEsiKeys(temp);
        setDrawerOpen(true);
    };

    const fullTablist: [string, number, React.ReactNode][] = [
        [
            "Details",
            0,
            <RegistrationGeneralPanel
                registration={registration}
                isNwaSession={isPartnerSession}
            />,
        ],
        [
            "Assessments",
            1,
            <AssessmentsPanel
                sessionDetails={session}
                registration={registration ?? undefined}
                handleJournalEntry={handleJournalEntry}
            />,
        ],
        [
            "Logs",
            2,
            <IndividualHistoryPanel
                journalTabKey={journalKey}
                imKey={registration?.imKey?.toString() ?? ""}
                emailAddress={registration?.emailAddress}
                idPrefix="registration-detail"
                onCreateJournalEntry={() => setDrawerOpen(true)}
                onlyRegistrationEvents={true}
                esiKey={registration?.esiKey?.toString() ?? ""}
            />,
        ],
    ];

    let tablist = [...fullTablist];
    if (
        !claimsService.IsUserInGroups([
            eGroupIds.eCCLSuperAdmin,
            eGroupIds.eCCLAdmin,
            eGroupIds.eNWAAdmin,
        ])
    ) {
        tablist = fullTablist.filter((x) => x[0] !== "Assesments");
    }

    function showErrorAlert(msg: string | undefined) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <div>
                    <Alert severity="error" sx={{ fontSize: 14 }}>
                        <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                        {msg}
                    </Alert>
                </div>
            </StandardLayout>
        );
    }

    function onSaveJournalEntry() {
        setJournalKey(Date.now());
        setDrawerOpen(false);
    }

    const allowedRoutes: RouteAccess = claimsService.GetCurrentAllowedRoutes();

    const breadcrumbs = (
        <Stack direction={"row"} spacing={3}>
            <SimpleBreadcrumbs>
                {allowedRoutes.allowRegistrations ? (
                    <Typography variant="h6" color="text.primary">
                        <Link key="findRegistrationLink" component={RouterLink} to="/registrations">
                            Registrations
                        </Link>
                    </Typography>
                ) : (
                    <Typography variant="h6" color="text.primary">
                        Registrations
                    </Typography>
                )}
                <Typography variant="h6" color="text.primary">
                    {registration.esiKey === undefined
                        ? ""
                        : registration.firstName + " " + registration.lastName}
                </Typography>
            </SimpleBreadcrumbs>
        </Stack>
    );

    if (regIsLoading || sessionIsLoading) {
        return (
            <StandardLayout breadcrumbs={breadcrumbs}>
                <PageLoader msg={""} />
            </StandardLayout>
        );
    }

    if (regIsSuccess) {
        if (esKey === "") {
            setEsKey(queryregistration.esKey.toString());
        } else if (sessionIsSuccess) {
            registration.session = JSON.parse(JSON.stringify(session));
            if (registration != null && session != null) {
                return (
                    <div ref={detailEl}>
                        {allowJournalEntries && (
                            <RegistrationJournalDrawer
                                esiKeys={esiKeys ?? []}
                                imKey={registration?.imKey?.toString() ?? ""}
                                email={registration?.emailAddress ?? ""}
                                sessionCode={registration?.projectCode ?? ""}
                                open={drawerOpen}
                                onClose={() => setDrawerOpen(false)}
                                onSave={() => onSaveJournalEntry()}
                            />
                        )}
                        <DetailPage
                            descriptor={"registration"}
                            breadcrumbs={breadcrumbs}
                            tablist={
                                isUserAdmin ? tablist : tablist.filter((t) => t[0] !== "Raters")
                            }
                            activeTab={0}
                        />
                    </div>
                );
            }
        } else if (!sessionIsError) {
            return showErrorAlert(`No registration data found for registration ${esikey}`);
        }
    }

    if (regIsError || sessionIsError) {
        var msg = regIsError ? getErrorMsg(regError) : getErrorMsg(sessionError);
        console.log(msg);
        return showErrorAlert(
            `Error retrieving registration data for registration key ${esikey} (${msg})`
        );
    }

    return showErrorAlert(
        `Unknown error retrieving registration data for registration key ${esikey}`
    );
};

export default RegistrationDetail;
