import React, { cloneElement, ReactElement } from "react";
import { Typography } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { eWhiteCellColors } from "./assessmentStatusColors";

export interface SelfStatusCellProps {
    status: number;
    scored: boolean;
    hasRaters: boolean;
}

const SelfStatusCell: React.FC<SelfStatusCellProps> = (props) => {
    let icon: ReactElement = <></>;
    let label: string = "";
    let color: eWhiteCellColors = eWhiteCellColors.Grey;

    switch (props.status) {
        case 1:
            label = "Not Accessed";
            icon = <RemoveCircleIcon />;
            color = eWhiteCellColors.Orange;
            break;
        case 2:
            label = "In Progress";
            icon = <WatchLaterIcon />;
            color = eWhiteCellColors.Blue;
            break;
        case 4:
            label = props.scored ? "Scored" : "Complete";
            icon = <CheckCircleIcon />;
            color = props.scored ? eWhiteCellColors.Green : eWhiteCellColors.Purple;
            break;
        case 5:
        default:
            label = "Cancelled";
            icon = <NotInterestedIcon />;
            color = eWhiteCellColors.Grey;
            break;
    }

    icon = cloneElement(icon, { fontSize: "small", style: { color: color as string } });

    return (
        <>
            {icon}
            <Typography sx={{ pl: 2 }}>{label}</Typography>
        </>
    );
};

export default SelfStatusCell;
