import React, { cloneElement, ReactElement } from "react";
import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContrastIcon from "@mui/icons-material/Contrast";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { eWhiteCellColors } from "./assessmentStatusColors";

export interface ScoringStatusCellProps {
    status: number;
}

const ScoringStatusCell: React.FC<ScoringStatusCellProps> = (props) => {
    let icon: ReactElement = <NotInterestedIcon />;
    let label: string = "Cancelled";
    let color: eWhiteCellColors = eWhiteCellColors.Grey;

    switch (props.status) {
        case 0:
            label = "Not Accessed";
            icon = <RemoveCircleIcon />;
            color = eWhiteCellColors.Orange;
            break;
        case 1:
            label = "Scorable";
            icon = <ContrastIcon />;
            color = eWhiteCellColors.Purple;
            break;
        case 2:
            label = "Scored";
            icon = <CheckCircleIcon />;
            color = eWhiteCellColors.Green;
            break;
        case 5:
        default:
            label = "Cancelled";
            icon = <NotInterestedIcon />;
            color = eWhiteCellColors.Grey;
            break;
    }

    icon = cloneElement(icon, { fontSize: "small", style: { color: color as string } });

    return (
        <>
            {icon}
            <Typography sx={{ pl: 2 }}>{label}</Typography>
        </>
    );
};

export default ScoringStatusCell;
